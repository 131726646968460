import Link from "next/link";

const PracticeLogo: React.FC = () => (
  <Link href="/">
    <img
      className="mx-auto h-16 w-auto"
      src="/favicon-310.png"
      alt="Practice logo"
    />
  </Link>
);

export default PracticeLogo;
