import React, { FC, ReactNode } from "react";
import Link from "next/link";

import PracticeLogo from "./Login/PracticeLogo";
import { Button } from "./Button";

interface ErrorPageButtonProps {
  children: ReactNode;
  href: string;
  target?: string;
  onClick?: () => void;
}

const ErrorPageButton: FC<ErrorPageButtonProps> = ({
  children,
  href,
  target,
  onClick,
}) => (
  <Link href={href} target={target ?? "_self"}>
    <Button
      onClick={onClick}
      className="w-full rounded-lg border text-black-ink hover:border-grey-800 px-4 py-2"
      variant="outline"
    >
      {children}
    </Button>
  </Link>
);

interface ErrorPageLayoutProps {
  buttons: JSX.Element;
  title: string;
  subtitle: string;
  children?: React.ReactNode;
}

const ErrorPageLayout: FC<ErrorPageLayoutProps> = ({
  buttons,
  title,
  subtitle,
  children,
}) => {
  return (
    <>
      <main className="grid min-h-full h-screen place-items-center bg-action-950 px-6 py-24 sm:py-32 lg:px-8">
        <div className="flex flex-col space-y-6 text-center sm:w-96">
          <PracticeLogo />
          <div>
            <h1 className="text-xl font-medium text-black-ink">{title}</h1>
            <p className="mt-2 text-base text-gray-500">{subtitle}</p>
          </div>
          {children}
          <div className="flex items-center justify-center gap-x-2">
            {buttons}
          </div>
        </div>
      </main>
    </>
  );
};

export { ErrorPageButton, ErrorPageLayout };
