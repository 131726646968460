import { NextPage } from "next";

import { ErrorPageButton, ErrorPageLayout } from "@components/ErrorPageLayout";

const Custom404: NextPage = () => {
  const buttons = (
    <>
      <ErrorPageButton href="/" target="_blank">
        Back home
      </ErrorPageButton>
      <ErrorPageButton href="/login" target="_blank">
        Admin Login
      </ErrorPageButton>
    </>
  );

  return (
    <ErrorPageLayout
      buttons={buttons}
      title="The page you're looking for could not be found."
      subtitle="This page might not be available anymore. Double check the link is spelled correctly. Error 404."
    />
  );
};

export default Custom404;
